html.dark {
  background-color: rgb(15, 23, 42);
}


.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 67%;
  height: 100%;
  border-radius: 9999px;
  background-color: RGBA(44,177,168,0.5);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  animation: sonarWave 1.5s linear infinite;
}

@keyframes sonarWave {
  from {
    opacity: 0.6;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}
